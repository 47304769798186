import { requireImage } from 'layout/image-container/image.utils';
import { ResolutionAwareProp } from 'types/resolution-aware-prop.type';
import {
  extractValueFromResolutionAwareProp,
  isLargeScreen,
} from 'helpers/layout.helpers';

export const preloadImage = (path: string): Promise<boolean> => {
  return new Promise(resolve => {
    const img = new Image();
    img.src = requireImage(path).lgSrcWebP;
    img.onload = () => {
      resolve(true);
    };
    img.onerror = () => {
      resolve(false);
    };
  });
};

export const preloadImages = (images: ResolutionAwareProp<string[]>) => {
  return Promise.all(
    (
      extractValueFromResolutionAwareProp(
        images,
        isLargeScreen() ? 'md' : 'sm',
      ) ?? []
    ).map(preloadImage),
  );
};
