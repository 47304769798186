import { TextInputProps } from './text-input.props';

export const TextInputPresets: TextInputProps = {
  id: '',
  type: 'text',
  clearable: true,
  variant: 'default',
  labelColor: 'black',
  descriptionColor: 'grey',
  placeholderColor: 'grey',
  actionColor: 'primary',
};
