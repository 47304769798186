import * as React from 'react';
import { forwardRef } from 'react';
import { ButtonProps } from './button.props';
import { ButtonPresets } from './button.presets';
import {
  StyledButton,
  StyledButtonContent,
  StyledProcessingOverlay,
} from 'core/button/button.styles';
import { Typography } from 'core/typography';
import { trackAnalyticsEvent, trackAnalyticsPageEvent } from 'utils/analytics';
import Link from 'next/link';
import { Icon } from 'core/icon';

const typographyColor = {
  primary: 'white',
  secondary: 'primary',
  default: 'primary',
  red: 'white',
  gold: 'white',
  blue: 'white',
  green: 'white',
  black: 'white',
};

export const Button = forwardRef((props: ButtonProps, ref: any) => {
  const {
    tx,
    text,
    txValues,
    textColor,
    textVariant,
    textStyle,
    fontSize,
    type,
    disabled,
    icon,
    iconLocation,
    linkHref,
    linkAs,
    linkTarget,
    linkPrefetch,
    processing,
    analyticsEvent,
    analyticsEventType,
    analyticsEventData,
    onClick,
    ...others
  } = Object.assign({}, ButtonPresets, props);

  const onButtonClick = () => {
    if (analyticsEvent) {
      if (analyticsEventType === 'page') {
        trackAnalyticsPageEvent(analyticsEvent);
      } else {
        trackAnalyticsEvent(analyticsEvent, analyticsEventData);
      }
    }

    if (onClick) {
      onClick();
    }
  };

  const color =
    textColor ??
    (others.variant === 'secondary' || others.variant === 'action'
      ? others.color || 'primary'
      : typographyColor[others.color ?? others.variant]);

  const styledButton = (
    <StyledButton
      type={type}
      disabled={disabled || processing}
      textVariant={textVariant}
      ref={ref}
      onClick={onButtonClick}
      {...others}>
      <StyledButtonContent {...others}>
        {icon && iconLocation === 'left' && icon}

        {(tx || text) && (
          <Typography
            variant={textVariant}
            fontSize={fontSize}
            color={color}
            tx={tx}
            text={text}
            txValues={txValues}
            textStyle={textStyle ?? 'bold'}
          />
        )}

        {icon && iconLocation === 'right' && icon}
      </StyledButtonContent>

      {others.variant !== 'action' && processing && (
        <StyledProcessingOverlay {...others}>
          <Icon name="loader" color={color} size="3rem" strokeWidth="4rem" />
        </StyledProcessingOverlay>
      )}
    </StyledButton>
  );

  return linkHref ? (
    <Link href={linkHref} as={linkAs} prefetch={linkPrefetch}>
      <a target={linkTarget}>{styledButton}</a>
    </Link>
  ) : (
    styledButton
  );
});
