import React from 'react';
import { useModal } from 'context/modal.context';
import { SubscribeModal } from './subscribe';
import { preloadImages } from 'utils/image';
import { getFromLocalStorage } from 'utils/storage';
import { useAsync } from 'react-use';
import { useCookies } from 'react-cookie';
import { AUTH_TOKEN } from 'constants/common';

export const UPSELL_REGISTERED = 'UPSELL_REGISTERED';
let modalShown = false;

const imagesToPreload = {
  lg: ['common/upsell.jpg'],
  sm: ['common/upsell-square.jpg'],
};

export const useSubscribeModal = (delay: number = 5000) => {
  const [cookies] = useCookies([AUTH_TOKEN]);

  const { showModal } = useModal({
    template: <SubscribeModal />,
    width: '75rem',
    variant: {
      lg: 'standard',
      md: 'fullscreen',
    },
  });

  useAsync(async () => {
    let clear;

    if (
      !cookies[AUTH_TOKEN] &&
      !modalShown &&
      !getFromLocalStorage(UPSELL_REGISTERED)
    ) {
      modalShown = true;

      if (clear) {
        clearTimeout(clear);
      }

      clear = setTimeout(async () => {
        await preloadImages(imagesToPreload);
        showModal();
      }, delay);
    }

    return () => {
      if (clear) {
        clearTimeout(clear);
      }
    };
  }, [cookies[AUTH_TOKEN], modalShown]);
};
