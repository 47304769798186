import React, { useEffect, useState } from 'react';
import { ImageContainer } from 'layout/image-container';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { StackedContainer } from 'layout/stacked-container';
import { Typography } from 'core/typography';
import { useFormik } from 'formik';
import { SubscribeFormSchema } from 'modals/subscribe/subscribe-form.schema';
import { TextInput } from 'core/text-input';
import { RoundButton } from 'core/round-button';
import { Icon } from 'core/icon';
import { BoxContainer } from 'layout/box-container';
import { StyledSubscribeForm } from 'modals/subscribe/subscribe.styles';
import { useAuth } from 'context/auth.context';
import { useSubscribe } from 'src/common/hooks';

export const SubscribeModal = () => {
  const [{ authenticated }] = useAuth();
  const [subscribed, setSubscribed] = useState(false);

  const subscribe = useSubscribe({ analyticsEvent: 'Sign Up - Upsell Modal' });

  useEffect(() => {
    setSubscribed(authenticated);
  }, [authenticated]);

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: SubscribeFormSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async ({ email }) => {
      const result = await subscribe(email);
      setSubscribed(result);
    },
  });

  return (
    <StyledSubscribeForm onSubmit={formik.handleSubmit} autoComplete="off">
      <Grid columns={{ lg: 2, md: 1 }} gap="unset" padding="unset" width="100%">
        <Cell>
          <ImageContainer
            src={{
              lg: 'common/upsell.jpg',
              md: 'common/upsell-square.jpg',
              sm: 'common/upsell-square.jpg',
            }}
            aspectRatio={{ lg: '1:1.25', md: '1:.66' }}
            immediateAvailable={true}
          />
        </Cell>

        <Cell>
          <StackedContainer
            alignItems="center"
            alignContent="center"
            padding={{ lg: 's5', md: 's4' }}>
            <Typography
              tx={subscribed ? 'register:registeredTitle' : 'register:title'}
              variant="h2"
            />

            <Typography
              tx={
                subscribed
                  ? 'register:registeredDescription'
                  : 'register:description'
              }
              color="grey"
            />

            {!subscribed && (
              <BoxContainer marginTop="s3">
                <TextInput
                  name="email"
                  placeholderTx="register:email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  autoFocus={true}
                  icon={
                    <RoundButton
                      color="primary"
                      icon={
                        <Icon name="right-arrow" color="white" size="1.6rem" />
                      }
                      disabled={!formik.isValid}
                    />
                  }
                />
              </BoxContainer>
            )}
          </StackedContainer>
        </Cell>
      </Grid>
    </StyledSubscribeForm>
  );
};
