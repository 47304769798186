import { environment } from 'config/environment';
import LogRocket from 'logrocket';

let initialized = false;

export const initLogRocket = () => {
  if (process.browser && !environment.IS_PRODUCTION) {
    LogRocket.init(environment.LOG_ROCKET_APP_ID);
    initialized = true;
  }
};

export const identifyLogRocket = (name: string, email: string) => {
  if (initialized) {
    LogRocket.identify(environment.LOG_ROCKET_APP_ID, {
      name,
      email,
    });
  }
};
