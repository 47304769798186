import * as React from 'react';
import { forwardRef } from 'react';
import { RoundButtonProps } from 'core/round-button/round-button.props';
import { RoundButtonPresets } from 'core/round-button/round-button.presets';
import { StyledRoundButton } from 'core/round-button/round-button.styles';
import { trackAnalyticsEvent } from 'utils/analytics';

export const RoundButton = forwardRef((props: RoundButtonProps, ref: any) => {
  const {
    icon,
    size,
    color,
    opacity,
    disabled,
    analyticsEvent,
    onClick,
  } = Object.assign({}, RoundButtonPresets, props);

  const onButtonClick = () => {
    if (onClick && !disabled) {
      if (analyticsEvent) {
        trackAnalyticsEvent(analyticsEvent);
      }

      onClick();
    }
  };

  return (
    <StyledRoundButton
      ref={ref}
      size={size}
      color={color}
      opacity={opacity}
      onClick={onButtonClick}
      disabled={disabled}>
      {icon}
    </StyledRoundButton>
  );
});
