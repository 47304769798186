import { DialogProps } from './dialog.props';
import { DialogPresets } from './dialog.presets';
import React from 'react';
import {
  StyledCloseButtonContainer,
  StyledDialog,
  StyledDialogBackdrop,
  StyledDialogContainer,
} from 'core/dialog/dialog.styles';
import { RoundButton } from 'core/round-button';
import { useSpring } from 'react-spring';
import { useNotification } from 'context/notification.context';
import { Icon } from 'core/icon/icon';

export const Dialog = (props: DialogProps) => {
  const {
    clickOutsideToClose,
    level,
    variant,
    width,
    height,
    dialogAnimatedProps,
    closable,
    onClose,
    onCloseCallback,
    children,
  } = Object.assign({}, DialogPresets, props);

  const { visible: notificationVisible } = useNotification();

  const { opacity, top } = useSpring({
    opacity: 1,
    top: notificationVisible ? 40 : 0,
    from: {
      opacity: 0,
      top: 0,
    },
    config: { duration: 300 },
  });

  const closeDialog = () => {
    onClose();

    if (onCloseCallback) {
      onCloseCallback();
    }
  };

  const onBackdropClose = (): void => {
    if (clickOutsideToClose) {
      closeDialog();
    }
  };

  return (
    <>
      <StyledDialogContainer level={level} variant={variant} width={width}>
        {closable && (
          <StyledCloseButtonContainer variant={variant} style={{ opacity }}>
            <RoundButton
              icon={<Icon name="close" color="white" size="1rem" />}
              color="translucentBlack"
              opacity={0.6}
              onClick={closeDialog}
            />
          </StyledCloseButtonContainer>
        )}
        <StyledDialog
          variant={variant}
          height={height}
          style={dialogAnimatedProps}>
          {children}
        </StyledDialog>
      </StyledDialogContainer>
      <StyledDialogBackdrop
        level={level}
        onClick={onBackdropClose}
        style={{ opacity, top }}
      />
    </>
  );
};
