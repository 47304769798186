import { useEffect, useMemo, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import {
  PaymentRequestOptions,
  PaymentRequestShippingAddressEvent,
  PaymentRequestShippingOptionEvent,
  PaymentRequestTokenEvent,
  StripePaymentRequestButtonElementOptions,
} from '@stripe/stripe-js';

export const useOptions = paymentRequest => {
  return useMemo(
    () =>
      ({
        paymentRequest,
        style: {
          paymentRequestButton: {
            theme: 'dark',
            height: '48px',
            type: 'buy',
          },
        },
      } as StripePaymentRequestButtonElementOptions),
    [paymentRequest],
  );
};

export interface UsePaymentRequestProps {
  options: PaymentRequestOptions;
  onShippingAddressChanged: (event: PaymentRequestShippingAddressEvent) => any;
  onShippingOptionChanged: (event: PaymentRequestShippingOptionEvent) => any;
  onTokenGenerated: (event: PaymentRequestTokenEvent) => any;
  onCancel: () => any;
}

export const usePaymentRequest = ({
  options,
  onShippingAddressChanged,
  onShippingOptionChanged,
  onTokenGenerated,
  onCancel,
}: UsePaymentRequestProps) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(null);

  useEffect(() => {
    if (stripe && paymentRequest === null) {
      const pr = stripe.paymentRequest(options);
      setPaymentRequest(pr);
    }
  }, [stripe, options, paymentRequest]);

  useEffect(() => {
    let subscribed = true;
    if (paymentRequest) {
      paymentRequest.canMakePayment().then(res => {
        if (res && subscribed) {
          setCanMakePayment(res);
        }
      });
    }

    return () => {
      subscribed = false;
    };
  }, [paymentRequest]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on('shippingaddresschange', onShippingAddressChanged);
    }
    return () => {
      if (paymentRequest) {
        paymentRequest.off('shippingaddresschange', onShippingAddressChanged);
      }
    };
  }, [paymentRequest, onShippingAddressChanged]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on('shippingoptionchange', onShippingOptionChanged);
    }
    return () => {
      if (paymentRequest) {
        paymentRequest.off('shippingoptionchange', onShippingOptionChanged);
      }
    };
  }, [paymentRequest, onShippingOptionChanged]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on('cancel', onCancel);
    }
    return () => {
      if (paymentRequest) {
        paymentRequest.off('cancel', onCancel);
      }
    };
  }, [paymentRequest, onCancel]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on('token', onTokenGenerated);
    }
    return () => {
      if (paymentRequest) {
        paymentRequest.off('token', onTokenGenerated);
      }
    };
  }, [paymentRequest, onTokenGenerated]);

  return {
    paymentRequest: canMakePayment ? paymentRequest : null,
    canMakePayment: !!canMakePayment,
    applePay: canMakePayment?.applePay,
  };
};
