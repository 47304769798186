import { api } from 'services/api';
import { useAuth } from 'context/auth.context';
import { useNotification } from 'context/notification.context';
import { useCallback } from 'react';
import { saveToLocalStorage } from 'utils/storage';
import { UPSELL_REGISTERED } from 'modals/subscribe';
import { useCurrentUrl } from 'utils/hooks';

type UseSubscribeProps = { analyticsEvent: string };
type UseSubscribeResponse = (email: string) => Promise<boolean>;

export const useSubscribe = (
  props?: UseSubscribeProps,
): UseSubscribeResponse => {
  const [{ campaignInfo }] = useAuth();
  const { notify } = useNotification();
  const currentUrl = useCurrentUrl();

  const subscribe = useCallback(
    async email => {
      const { success, data, error } = await api.auth.signUp({
        email,
        ...campaignInfo,
        source: currentUrl,
        signUpEventName: props?.analyticsEvent,
      });

      if (success) {
        notify({
          messageTx: 'signIn:thankYou',
        });

        saveToLocalStorage(UPSELL_REGISTERED, true);

        return true;
      } else {
        if (data?.alreadySignedUp) {
          notify({
            messageTx: 'signIn:thankYou',
          });

          saveToLocalStorage(UPSELL_REGISTERED, true);

          return true;
        } else {
          notify({
            message: error.message,
            isError: true,
          });

          return false;
        }
      }
    },
    [props?.analyticsEvent, currentUrl],
  );

  return subscribe;
};
