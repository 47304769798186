import React, {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useRef,
} from 'react';
import { useImmerReducer } from 'use-immer';
import { ThemeColor } from 'styles/theme';

export interface Notification {
  message?: string;
  messageTx?: string;
  isError?: boolean;
  timeout?: number;
}

interface State extends Notification {
  visible?: boolean;
  preloaderVisible?: boolean;
  preloaderBackgroundColor?: ThemeColor;
}

const initialState: State = {
  visible: false,
  isError: false,
};

type Action =
  | { type: 'SHOW_NOTIFICATION'; data: State }
  | { type: 'HIDE_NOTIFICATION' }
  | { type: 'SHOW_PRELOADER'; background?: ThemeColor }
  | { type: 'HIDE_PRELOADER' };

function notificationReducer(draft: State, action: Action) {
  switch (action.type) {
    case 'SHOW_NOTIFICATION':
      draft = Object.assign({}, draft, action.data, { visible: true });
      return draft;
    case 'HIDE_NOTIFICATION':
      draft.visible = false;
      draft.message = '';
      draft.messageTx = '';
      return draft;
    case 'SHOW_PRELOADER':
      draft.preloaderVisible = true;
      draft.preloaderBackgroundColor = action.background;
      return draft;
    case 'HIDE_PRELOADER':
      draft.preloaderVisible = false;
      return draft;
  }
}

const NotificationStateContext = createContext<State>(initialState);

const NotificationDispatchContext = createContext<Dispatch<Action> | undefined>(
  undefined,
);

export const NotificationsProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;

  const [state, dispatch] = useImmerReducer(notificationReducer, initialState);

  return (
    <NotificationStateContext.Provider value={state}>
      <NotificationDispatchContext.Provider value={dispatch}>
        {children}
      </NotificationDispatchContext.Provider>
    </NotificationStateContext.Provider>
  );
};

export function useNotificationState(): State {
  const context = useContext(NotificationStateContext);

  if (context === undefined) {
    throw new Error(
      `useNotification must be used inside an NotificationProvider`,
    );
  }

  return context;
}

export function useNotificationDispatch() {
  const context = useContext(NotificationDispatchContext);

  if (context === undefined) {
    throw new Error(
      `useNotification must be used inside an NotificationProvider`,
    );
  }

  return context;
}

const PRELOADER_WAITING_TIME = 500;

export const useNotification = () => {
  const state = useNotificationState();
  const dispatch = useNotificationDispatch();
  const waitingToShowPreloader = useRef(false);

  const notify = useCallback((notification: string | Notification) => {
    const data =
      typeof notification === 'string'
        ? {
            message: notification,
            isError: false,
          }
        : Object.assign({ isError: false }, notification);

    dispatch({ type: 'SHOW_NOTIFICATION', data });
  }, []);

  const notifyError = useCallback((notification: string | Notification) => {
    const data =
      typeof notification === 'string'
        ? {
            message: notification,
            isError: true,
          }
        : Object.assign({ isError: true }, notification);

    dispatch({ type: 'SHOW_NOTIFICATION', data });
  }, []);

  const hideNotification = useCallback(() => {
    dispatch({ type: 'HIDE_NOTIFICATION' });
  }, []);

  const showPreloader = useCallback(
    (background?: ThemeColor) => {
      waitingToShowPreloader.current = true;

      setTimeout(() => {
        if (waitingToShowPreloader.current) {
          dispatch({ type: 'SHOW_PRELOADER', background });
        }
      }, PRELOADER_WAITING_TIME);
    },
    [waitingToShowPreloader.current],
  );

  const hidePreloader = useCallback(() => {
    waitingToShowPreloader.current = false;
    dispatch({ type: 'HIDE_PRELOADER' });
  }, []);

  const togglePreloader = useCallback(() => {
    if (state.preloaderVisible) {
      hidePreloader();
    } else {
      showPreloader();
    }
  }, []);

  return {
    ...state,
    notify,
    notifyError,
    hideNotification,
    showPreloader,
    hidePreloader,
    togglePreloader,
  };
};
