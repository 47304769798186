import { useEffect } from 'react';
import { trackAnalyticsEvent, trackAnalyticsPageEvent } from 'utils/analytics';

export interface UseTrackAnalyticsProps {
  enterEvent?: string;
  enterEventData?: any;
  leaveEvent?: string;
  leaveEventData?: any;
  enterEventType?: 'page' | 'event';
}

export const useTrackAnalytics = (props: UseTrackAnalyticsProps) => {
  useEffect(() => {
    if (props.enterEvent) {
      if (props.enterEventType === 'page') {
        trackAnalyticsPageEvent(props.enterEvent);
      } else {
        trackAnalyticsEvent(props.enterEvent, props.enterEventData);
      }
    }

    return () => {
      if (props.leaveEvent) {
        trackAnalyticsEvent(props.leaveEvent, props.leaveEventData);
      }
    };
  }, []);
};
