import styled, { css } from 'styled-components';
import { DialogProps } from 'core/dialog/dialog.props';
import { animated } from 'react-spring';
import { media } from 'styles/media';
import { ExtractStyleFromResolutionAwarePropEntry } from 'types/layout';
import { generateStylesForResolutionAwareProps } from 'helpers/layout.helpers';

export const StyledDialogContainer = styled.div<DialogProps>`
  ${({ level, variant, width }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'width',
        resolutionAwareProp: width,
        valueFormatter: (value: string, size: string) =>
          variant[size] === 'fullscreen' ? '100vw' : value ?? 'max-content',
      },
      {
        cssProperty: 'height',
        resolutionAwareProp: variant,
        valueFormatter: value =>
          value === 'fullscreen' ? '-webkit-fill-available' : 'max-content',
      },
      {
        cssProperty: 'max-width',
        resolutionAwareProp: variant,
        valueFormatter: value => (value === 'fullscreen' ? '100vw' : '90vw'),
      },
      {
        cssProperty: 'max-height',
        resolutionAwareProp: variant,
        valueFormatter: value => (value === 'fullscreen' ? '100vh' : '90vh'),
      },
    ];

    return css`
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      z-index: ${1000000 + level + 1};
      ${generateStylesForResolutionAwareProps(entries)};
    `;
  }}
`;

export const StyledDialog = styled(animated.div)<DialogProps>`
  ${({ theme, height, variant }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'width',
        resolutionAwareProp: variant,
        valueFormatter: value => (value === 'fullscreen' ? '100vw' : 'unset'),
      },
      {
        cssProperty: 'height',
        resolutionAwareProp: variant,
        valueFormatter: (value: string) =>
          value === 'fullscreen' ? '100vh' : 'unset',
      },
      {
        cssProperty: 'height',
        resolutionAwareProp: height,
      },
      {
        cssProperty: 'border-radius',
        resolutionAwareProp: variant,
        valueFormatter: value =>
          value === 'fullscreen' ? 'unset' : theme.borderRadius,
      },
    ];

    return css`
      display: flex;
      background-color: ${theme.colors.white};
      overflow: hidden;
      ${generateStylesForResolutionAwareProps(entries)};
    `;
  }}
`;

export const StyledDialogBackdrop = styled(animated.div)<DialogProps>`
  ${({ theme, level }) => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${theme.colors.translucentBlack};
    z-index: ${1000000 + level};
  `}
`;

export const StyledCloseButtonContainer = styled(animated.div)<DialogProps>`
  ${({ theme, variant }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'display',
        resolutionAwareProp: variant,
        valueFormatter: value => (value === 'dialog' ? 'none' : 'flex'),
      },
    ];

    const isCloseIconFixed =
      (variant['sm'] || variant['md'] || variant) === 'fullscreen';

    return css`
      display: flex;
      position: absolute;
      top: -32px;
      right: -32px;

      ${generateStylesForResolutionAwareProps(entries)};

      ${media.lessThan('md')`
        position: ${isCloseIconFixed && 'fixed'};
        top: ${isCloseIconFixed ? theme.grid.gutterSmaller : '-4.2rem'};
        right: ${isCloseIconFixed ? theme.grid.gutterSmaller : '0'};;
        z-index: 3
      `}
    `;
  }}
`;
