import { ButtonProps } from './button.props';

export const ButtonPresets: Partial<ButtonProps> = {
  variant: 'primary',
  iconLocation: 'left',
  height: { lg: '4.8rem', sm: '4rem' },
  disabled: false,
  type: 'button',
  textVariant: 'p2',
  linkTarget: '_self',
  linkPrefetch: false,
  borderRadius: '2.5rem',
};
