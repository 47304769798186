import React from 'react';
import { Dialog } from 'core/dialog';
import { useTransition } from 'react-spring';
import { DialogsManagerProps } from 'layout/dialogs-manager/dialogs-manager.props';
import { useLockBodyScroll } from 'react-use';
import { ModalInstance } from 'context/modal.context';

let index = 0;
export const DialogsManager = (props: DialogsManagerProps) => {
  const { instances, closeLastOpened } = props;

  const transition = useTransition(instances, {
    from: { opacity: 0 },
    enter: { opacity: 1, config: { duration: 250 } },
    leave: { opacity: 0, config: { duration: 100 } },
  });

  useLockBodyScroll(!!instances?.length);

  return transition((style, active: ModalInstance) => (
    <Dialog
      level={++index}
      dialogAnimatedProps={style}
      variant={active.variant}
      width={active.width}
      height={active.height}
      closable={active.closable ?? true}
      onClose={closeLastOpened}
      onCloseCallback={active.onClose}
      children={active.template}
    />
  ));
};
