import { useCallback } from 'react';
import { AUTH_TOKEN, CART_ITEMS, FIREBASE_TOKEN } from 'constants/common';
import { useAuth } from 'context/auth.context';
import { useCookies } from 'react-cookie';
import { User } from 'types/user.type';
import { api } from 'services/api';
import { identifyLogRocket } from 'utils/log-rocket';
import { CartRecord, useCart } from 'context/cart.context';
import { useNotification } from 'context/notification.context';
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  saveToLocalStorage,
} from 'utils/storage';
import { useRouter } from 'next/router';
import { environment } from 'config/environment';

export interface UpdateSessionDataProps {
  user: User;
  token: string;
  firebaseToken?: string;
  updateCart?: boolean;
}

export const useSession = () => {
  const [, dispatch] = useAuth();
  const [, cartDispatch] = useCart();
  const [, setCookie] = useCookies([AUTH_TOKEN, FIREBASE_TOKEN]);
  const { showPreloader, hidePreloader } = useNotification();
  const { asPath } = useRouter();

  const updateSessionData = useCallback(
    async ({
      user,
      token,
      firebaseToken,
      updateCart = true,
    }: UpdateSessionDataProps) => {
      showPreloader();
      setCookie(AUTH_TOKEN, token, {
        path: '/',
        domain: environment.COOKIES_DOMAIN,
      });
      setCookie(FIREBASE_TOKEN, firebaseToken, {
        path: '/',
        domain: environment.COOKIES_DOMAIN,
      });

      api.registerAuthHeader(token);

      dispatch({
        type: 'SET_PROPERTIES',
        data: {
          user,
          token,
          firebaseToken,
          authenticated: true,
          status: 'AUTHENTICATED',
          currentEmail: '',
        },
      });

      if (updateCart) {
        let temporal: CartRecord[] = asPath.includes('/checkout')
          ? []
          : user.cart;

        const cartItems = getFromLocalStorage(CART_ITEMS) || [];

        if (cartItems?.length) {
          temporal = temporal.concat(cartItems);

          await api.cart.update({ cart: temporal });

          saveToLocalStorage(CART_ITEMS, temporal);
        }

        cartDispatch({
          type: 'SET_PROPERTIES',
          data: {
            records: temporal,
            wishlist: user.wishlist || [],
          },
        });
      } else {
        removeFromLocalStorage(CART_ITEMS);

        cartDispatch({
          type: 'SET_PROPERTIES',
          data: {
            records: [],
            wishlist: user.wishlist || [],
          },
        });
      }

      identifyLogRocket(`${user.firstName} ${user.lastName}`, user.email);

      hidePreloader();
    },
    [],
  );

  return { updateSessionData };
};
