import React, { forwardRef, useEffect, useState } from 'react';
import { TextInputProps } from './text-input.props';
import { TextInputPresets } from './text-input.presets';
import {
  StyleClearableIconContainer,
  StyledBodyContainer,
  StyledIconContainer,
  StyledInput,
  StyledInputActionContainer,
  StyledInputContainer,
  StyledInputContent,
  StyledInputDescription,
  StyledPlaceholder,
} from './text-input.styles';
import { Typography } from '../typography';
import { Button } from 'core/button';
import { animated, useSpring } from 'react-spring';
import { useEnsuredForwardedRef } from 'react-use';
import { Icon } from 'core/icon/icon';
import InputMask from 'react-input-mask';
import { useMounted } from 'utils/hooks';
import { isLargeScreen } from 'helpers/layout.helpers';

const MASKS = {
  phone: '9999999999',
};

export const TextInput: React.FunctionComponent<TextInputProps> = forwardRef(
  (props: TextInputProps, ref: any) => {
    const name = props.name || props.label || props.labelTx;
    const {
      id,
      label,
      labelTx,
      labelColor,
      description,
      descriptionTx,
      descriptionColor,
      placeholder,
      placeholderTx,
      placeholderColor,
      value,
      icon,
      clearable,
      readonly,
      disabled,
      autoFocus,
      selectOnFocus,
      type,
      actionTx,
      actionText,
      actionColor,
      variant,
      mask,
      error,
      errorTx,
      onActionClick,
      onIconClick,
      onBlur: onInputBlur,
      onChange,
      onTextChange,
      onEnter,
      onFocus: onInputFocus,
      ...others
    } = Object.assign({}, TextInputPresets, props, {
      name,
      id: props.id,
    });

    const inputRef: any = useEnsuredForwardedRef(ref);

    const [text, setText] = useState(value);
    const [focused, setFocused] = useState(false);

    const mounted = useMounted();

    const focus = () => {
      inputRef.current.focus();
    };

    const onFieldChange = event => {
      const value = event.target.value;

      setText(value);

      if (onTextChange) {
        onTextChange(value);
      }

      if (onChange) {
        onChange(event);
      }
    };

    const onKeyUp = (event: KeyboardEvent) => {
      if (onEnter && event.key === 'Enter') {
        onEnter(value);
      }
    };

    const onFocus = event => {
      setFocused(true);

      if (selectOnFocus) {
        inputRef.current.select();
      }

      if (onInputFocus) {
        onInputFocus(event);
      }
    };

    const onBlur = event => {
      setFocused(false);

      if (onInputBlur) {
        onInputBlur(event);
      }
    };

    useEffect(() => {
      if (autoFocus && isLargeScreen()) {
        focus();
      }
    }, [autoFocus]);

    useEffect(() => {
      setText(value);
    }, [value]);

    const onInputClear = (): void => {
      inputRef.current.value = '';
      onFieldChange({ target: inputRef.current });
    };

    const spring = useSpring({
      opacity:
        clearable &&
        focused &&
        !readonly &&
        !disabled &&
        !!value &&
        !actionText &&
        !actionTx
          ? 1
          : 0,
    });

    const onActionButtonClick = () => {
      if (onActionClick) {
        onActionClick(inputRef.current);
      }
    };

    const onButtonIconClick = () => {
      if (onIconClick) {
        onIconClick(inputRef.current);
      }
    };

    return (
      <StyledInputContainer>
        {(label || labelTx) && (
          <Typography
            variant="label"
            text={label}
            tx={labelTx}
            color={props.touched && (error || errorTx) ? 'red' : labelColor}
          />
        )}
        {(description || descriptionTx) && (
          <StyledInputDescription
            variant="p3"
            text={description}
            tx={descriptionTx}
            color={descriptionColor}
          />
        )}
        <StyledBodyContainer>
          <StyledInputContent
            focused={focused}
            variant={variant}
            invalid={!!(errorTx || error)}>
            {mask && mounted ? (
              <InputMask
                mask={MASKS[mask]}
                maskPlaceholder={null}
                id={id}
                name={name}
                type={type}
                value={text ?? ''}
                readOnly={readonly}
                disabled={disabled}
                variant={variant}
                invalid={!!(errorTx || error)}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onFieldChange}
                onKeyUp={onKeyUp}
                {...others}>
                <StyledInput ref={inputRef} />
              </InputMask>
            ) : (
              <StyledInput
                id={id}
                name={name}
                type={type}
                ref={inputRef}
                value={text ?? ''}
                readOnly={readonly}
                disabled={disabled}
                variant={variant}
                invalid={!!(errorTx || error)}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onFieldChange}
                onKeyUp={onKeyUp}
                {...others}
              />
            )}

            {!text && (placeholder || placeholderTx) && (
              <StyledPlaceholder
                tx={placeholderTx}
                text={placeholder}
                color={
                  props.touched && (error || errorTx) ? 'red' : placeholderColor
                }
              />
            )}

            {clearable && (
              <animated.div style={spring}>
                <StyleClearableIconContainer onClick={onInputClear}>
                  <Icon name="close" color="grey" size="1rem" />
                </StyleClearableIconContainer>
              </animated.div>
            )}

            {(actionText || actionTx) && (
              <StyledInputActionContainer>
                <Button
                  variant="action"
                  text={actionText}
                  tx={actionTx}
                  textColor={actionColor}
                  width="unset"
                  onClick={onActionButtonClick}
                />
              </StyledInputActionContainer>
            )}
          </StyledInputContent>
          {!!icon && (
            <StyledIconContainer onClick={onButtonIconClick}>
              {icon}
            </StyledIconContainer>
          )}
        </StyledBodyContainer>
      </StyledInputContainer>
    );
  },
);
