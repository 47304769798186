import React, {
  createContext,
  Dispatch,
  ReactNode,
  useCallback,
  useContext,
} from 'react';
import { useImmerReducer } from 'use-immer';
import { v4 as uuid4 } from 'uuid';
import { DialogsManager } from 'layout/dialogs-manager';
import { ResolutionAwareProp } from 'types/resolution-aware-prop.type';
import { DialogVariant } from 'core/dialog/dialog.props';

export interface ModalInstance {
  key?: string;
  template: ReactNode;
  variant?: ResolutionAwareProp<DialogVariant>;
  width?: ResolutionAwareProp<string>;
  height?: ResolutionAwareProp<string>;
  closable?: boolean;
  onClose?: (data?: any) => void;
}

interface State {
  instances?: ModalInstance[];
  queuedInstances?: ModalInstance[];
}

const initialState: State = {
  instances: [],
  queuedInstances: [],
};

type Action =
  | { type: 'REGISTER'; instance: ModalInstance }
  | { type: 'UNREGISTER'; key: string }
  | { type: 'UNREGISTER_LAST_OPENED' }
  | { type: 'UNREGISTER_ALL' }
  | { type: 'RESET' };

function modalReducer(draft: State, action: Action) {
  switch (action.type) {
    case 'REGISTER':
      if (draft.instances.length) {
        draft.queuedInstances.push(action.instance);
      } else {
        draft.instances.push(action.instance);
      }
      return draft;
    case 'UNREGISTER':
      draft.instances = draft.instances.filter(
        instance => instance.key === action.key,
      );

      if (!draft.instances.length && draft.queuedInstances.length) {
        draft.instances = [draft.queuedInstances.pop()];
      }

      return draft;
    case 'UNREGISTER_LAST_OPENED':
      draft.instances.pop();

      if (!draft.instances.length && draft.queuedInstances.length) {
        draft.instances = [draft.queuedInstances.pop()];
      }

      return draft;
    case 'UNREGISTER_ALL':
      draft.queuedInstances = [];
      draft.instances = [];
      return draft;
    case 'RESET':
      return initialState;
  }
}

const ModalStateContext = createContext<State>(initialState);

const ModalDispatchContext = createContext<Dispatch<Action> | undefined>(
  undefined,
);

export const ModalProvider = (props: {
  value?: typeof initialState;
  children: React.ReactNode;
}) => {
  const { value, children } = props;

  const [state, dispatch] = useImmerReducer(
    modalReducer,
    Object.assign({}, initialState, value),
  );

  const instanceValue = Object.assign({}, state);

  const closeLastOpened = () => {
    dispatch({ type: 'UNREGISTER_LAST_OPENED' });
  };

  return (
    <ModalStateContext.Provider value={instanceValue}>
      <ModalDispatchContext.Provider value={dispatch}>
        {children}

        {process.browser ? (
          <DialogsManager
            instances={state.instances}
            closeLastOpened={closeLastOpened}
          />
        ) : null}
      </ModalDispatchContext.Provider>
    </ModalStateContext.Provider>
  );
};

export function useModalState(): State {
  const context = useContext(ModalStateContext);

  if (context === undefined) {
    throw new Error(`useModal must be used inside an ModalProvider`);
  }

  return context;
}

export function useModalDispatch() {
  const context = useContext(ModalDispatchContext);

  if (context === undefined) {
    throw new Error(`useModal must be used inside an ModalProvider`);
  }

  return context;
}

export const useModal = (instance: ModalInstance, dependencies: any[] = []) => {
  const key = uuid4();
  const dispatch = useModalDispatch();

  const showModal = useCallback(() => {
    dispatch({
      type: 'REGISTER',
      instance: Object.assign({}, instance, {
        key,
        variant: instance.variant ?? 'standard',
      }),
    });
  }, [key, dependencies]);

  const hideModal = useCallback(() => {
    dispatch({ type: 'UNREGISTER', key });
  }, [key]);

  return { showModal, hideModal };
};

export const useModalClose = () => {
  const dispatch = useModalDispatch();

  const closeLastOpened = useCallback(() => {
    dispatch({ type: 'UNREGISTER_LAST_OPENED' });
  }, []);

  const clearModalsStack = useCallback(() => {
    dispatch({ type: 'UNREGISTER_ALL' });
  }, []);

  return { closeLastOpened, clearModalsStack };
};
